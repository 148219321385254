import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = [ 
        "icon",
        "modal"
    ]

    showModal(event) {
        event.preventDefault()
        this.modalTarget.showModal()

        this.modalTarget.addEventListener(
  	        'click',
            ((event) => {
                let rect = event.target.getBoundingClientRect();
                if (rect.left > event.clientX ||
                    rect.right < event.clientX ||
                    rect.top > event.clientY ||
                    rect.bottom < event.clientY) {
                        this.modalTarget.close()
                }
            })
        )
    }

    closeModal(event) {
        event.preventDefault()
        this.modalTarget.close()
    }

}
